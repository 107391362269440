//https://css-tricks.com/snippets/css/fluid-typography/
@mixin fluid-type($min-value, $max-value, $breakpoint-min:sm, $breakpoint-max:xl) {
    & {
        font-size: $min-value;

        @include media-breakpoint-up($breakpoint-min) {
            font-size: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{breakpoint-rem($breakpoint-min)}rem) / #{strip-unit(breakpoint-rem($breakpoint-max) - breakpoint-rem($breakpoint-min))}));
        }

        @include media-breakpoint-up($breakpoint-max) {
            font-size: $max-value;
        }
    }
}

@function breakpoint-rem($breakpoint) {
    @return strip-unit(breakpoint-min($breakpoint) / 16);
}
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}
//Override Bootstrap
@mixin make-container($gutter: $grid-gutter-width) {
    width: 100%;
    padding-right: $gutter * 2;
    padding-left: $gutter * 2;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-up(lg) {
        padding-right: $gutter;
        padding-left: $gutter;
    }

    @media(min-width: map_get($container-max-widths, xl)){
        padding-right: 0;
        padding-left: 0;
    }
}

@mixin make-row($gutter: $grid-gutter-width) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter / 2;
    margin-left: -$gutter / 2;

    @include media-breakpoint-up(lg) {
        margin-right: -$grid-gutter-width-lg / 2;
        margin-left: -$grid-gutter-width-lg / 2;
    }
}
@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
    // Common properties for all breakpoints
    %grid-column {
        position: relative;
        width: 100%;
        padding-right: $gutter / 2;
        padding-left: $gutter / 2;

        @include media-breakpoint-up(lg) {
            padding-right: $grid-gutter-width-lg / 2;
            padding-left: $grid-gutter-width-lg / 2;
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @if $columns > 0 {
            // Allow columns to stretch full width below their breakpoints
            @for $i from 1 through $columns {
                .col#{$infix}-#{$i} {
                    @extend %grid-column;
                }
            }
        }

        .col#{$infix},
        .col#{$infix}-auto {
            @extend %grid-column;
        }

        @include media-breakpoint-up($breakpoint, $breakpoints) {
            // Provide basic `.col-{bp}` classes for equal-width flexbox columns
            .col#{$infix} {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            @if $grid-row-columns > 0 {
                @for $i from 1 through $grid-row-columns {
                    .row-cols#{$infix}-#{$i} {
                        @include row-cols($i);
                    }
                }
            }

            .col#{$infix}-auto {
                @include make-col-auto();
            }

            @if $columns > 0 {
                @for $i from 1 through $columns {
                    .col#{$infix}-#{$i} {
                        @include make-col($i, $columns);
                    }
                }
            }

            .order#{$infix}-first { order: -1; }

            .order#{$infix}-last { order: $columns + 1; }

            @for $i from 0 through $columns {
                .order#{$infix}-#{$i} { order: $i; }
            }

            @if $columns > 0 {
                // `$columns - 1` because offsetting by the width of an entire row isn't possible
                @for $i from 0 through ($columns - 1) {
                    @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
                        .offset#{$infix}-#{$i} {
                            @include make-col-offset($i, $columns);
                        }
                    }
                }
            }
        }
    }
}

@mixin custom-form-validation-state($state, $color, $icon) {
    /*.#{$state}-feedback {
        display: none;
        width: 100%;
        margin-top: $form-feedback-margin-top;
        @include font-size($form-feedback-font-size);
        color: $color;
    }

    @include form-validation-state-selector($state) {
        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
            display: block;
        }
    }*/

    .form-control {
        @include form-validation-state-selector($state) {
            padding-right: $input-padding-x * 2;
            background-image: escape-svg($icon);
            background-repeat: no-repeat;
            background-position: right $input-padding-x center;
            background-size: 1rem 1rem;


            @include media-breakpoint-up(lg) {
                padding-right: $input-padding-x-lg * 2;
                background-position: right $input-padding-x-lg center;
            }
        }
    }

    // stylelint-disable-next-line selector-no-qualifying-type
    textarea.form-control {
        @include form-validation-state-selector($state) {
            padding-right: $input-padding-x * 2;
            background-position: top $input-padding-top right $input-padding-x;

            @include media-breakpoint-up(lg) {
                padding-right: $input-padding-x-lg * 2;
                background-position: top $input-padding-top-lg right $input-padding-x-lg;
            }
        }
    }

    .custom-select {
        @include form-validation-state-selector($state) {
            padding-right: $custom-select-feedback-icon-padding-right;

        }
    }

    .custom-control-input {
        @include form-validation-state-selector($state) {
            //show only invalid state here.. green checkboxes are ugly..
            @if $state == "invalid" {
                ~ .custom-control-label {
                    color: $color;
                }
            }


            /*            &:checked {
                            ~ .custom-control-label::before {
                                border-color: lighten($color, 10%);
                                @include gradient-bg(lighten($color, 10%));
                            }
                        }

                        &:focus {
                            ~ .custom-control-label::before {
                                box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                            }

                            &:not(:checked) ~ .custom-control-label::before {
                                border-color: $color;
                            }
                        }*/
        }
    }
}
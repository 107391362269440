// Teaser

$teaser-spacer: 5px;

.teaser-grid {
    margin: 30px (-$teaser-spacer) 0;

    @include media-breakpoint-up(md) {
        width: 50%;
        margin: 30px auto 0;
    }

    @include media-breakpoint-up(lg) {
        width: auto;
        margin: 45px (-$teaser-spacer*2) 0;
    }

    &.layout-3-columns {
        display: flex;
        flex-wrap: wrap;

        .teaser {
            @include media-breakpoint-up(lg) {
                width: 33.33333%;
            }
        }
    }
}

.teaser {
    position: relative;
    width: 100%;

    &::before {
        content: '';
        display: block;
        padding-top: 100%;

        @if($env == 'adm') {
            padding-top: 75%;
        }
    }

    .teaser-inner {
        position: absolute;
        top: $teaser-spacer;
        left: $teaser-spacer;
        right: $teaser-spacer;
        bottom: $teaser-spacer;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            top: ($teaser-spacer*2);
            left: ($teaser-spacer*2);
            right: ($teaser-spacer*2);
            bottom: ($teaser-spacer*2);
        }

        > a {
            display: inline-block;

            @if($env == 'adm') {
                width: 100%;
                height: 100%;
                position: relative;

                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(to top,rgba(0,0,0,.55) 0,rgba(0,0,0,0) 40%);
                    z-index: 1;
                }
            }
        }

        .teaser-img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            transition: $default-transition;
        }

        .teaser-title {
            position: absolute;
            display: block;
            width: 100%;
            text-align: center;
            color: $secondary;
            font-family: $font-family-myriadpro;
            font-weight: $font-weight-bold;
            line-height: 1.2857;
            text-transform: uppercase;
            left: 0;
            bottom: $teaser-spacer*3;
            padding: 0 $teaser-spacer*3;

            &.blue {
                color: $darkblue;
            }

            &.yellow {
                color: $yellow;
            }

            &.white {
                color: $white;
            }


            @if($env == 'adm') {
                z-index: 2;
            }


            @include fluid-type($teaser-font-size, $teaser-font-size-xl);
        }
    }

    @include hover() {
        .teaser-img {
            transform: scale(1.025, 1.025);
        }
    }
}
